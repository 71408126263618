@mixin ftr-variant($ftr-text, $ftr-bg, $ftr-separator, $ftr-highlight) {
  color: $ftr-text;
  background-color: $ftr-bg;
  border-color: $ftr-separator;

  .ftr-bb {
    border-color: $ftr-separator;
  }

  .ftr-bt {
    border-color: $ftr-separator;
  }

  .ftr-column-title {
    color: $ftr-highlight;
  }

  a,
  a:hover,
  a:link {
    color: $ftr-text;
  }

  .ftr-oneline {
    color: $ftr-highlight;

    a,
    a:hover,
    a:link {
      color: $ftr-highlight;
    }
  }
}

.ftr-oneline {
  padding: ($spacer * 1.5) 0;
}

.ftr-columns {
  padding-bottom: ($spacer * 4.5);
  padding-top: ($spacer * 4.5);
}

.ftr-bb {
  border-bottom: 1px solid transparent;
}

.ftr-bt {
  border-top: 1px solid transparent;
}

.ftr-column-list {
  list-style: none;
  padding-left: 0;
  padding-top: $spacer * 1.5;
}

//@mixin ftr-variant($ftr-text, $ftr-bg, $ftr-separator, $ftr-highlight) {
@each $name, $colors in $theme-colors-extended {
  .ftr-#{$name} {
    @include ftr-variant (
        map-get($colors, contrast),
        map-get($colors, color),
        map-get($colors, border),
        map-get($colors, highlight)
    );
  }
}
