/*! Bootstrap 4 "Charming" Theme by HackerThemes, https://hackerthemes.com */

@import 'functions'; // map-get
@import 'colors';
@import 'variables';

// Bootstrap variables end: Import Bootstrap
@import '../../../node_modules/bootstrap/scss/bootstrap';
@import '../../../scss/common-utils';


// Customized Bootstrap
@import 'alerts';


// Charming stuff
@import 'mixins/mixins';
@import 'mixins/colors';

@import 'buttons';
@import 'forms';
@import 'icons';
@import 'type';
@import 'utilities';
