.block {
  // Horizontal Module
  //background: linear-gradient(to bottom, $block-bg-gstart 0%, $block-bg-gstop 100%);
  //padding: $block-padding ($block-padding / 2);
  padding: $block-padding 0;

  @include media-breakpoint-up(sm) {
    //padding: ($block-padding * 2) $block-padding;
    padding: ($block-padding * 2) 0;
  }

  .container & {
    // If it's a child of a container we give it a nice round edge
    border-radius: $border-radius;
  }
}

.block-bb {
  border-bottom: 2px solid $block-border;
}

.block-bt {
  border-top: 2px solid $block-border;
}

.block-heading {
  margin-bottom: $spacer * 4.5;
  text-align: center;
}

.block-title {
  font-weight: bold;
  margin-bottom: 0;
}

.block-subtitle {
  margin-bottom: 0;
}

.block-image {
  // A block with a stock or other image
  align-items: center;
  display: flex;

  @include media-breakpoint-up(lg) {
    height: 600px;
    max-height: 600px;
    min-height: 432px;
  }
}

@each $name, $colors in $theme-colors-extended {
  .block-#{$name} {
    @include bg-gradient(map-get($colors, gstart), map-get($colors, gstop));
    color: map-get($colors, contrast);
  }
}

.block-image-mainboard {
  background-image: url('../../../assets/bg-images/JumbotronBG.png');
}

.block-image-elegant {
  background-image: url('../../../assets/bg-images/charming-bg-elegant-xl.jpg');
  background-position: center;

  @media screen and (min-width: 1921px) {
    background-image: url('../../../assets/bg-images/charming-bg-elegant-xxl.jpg');
  }

  @include media-breakpoint-down(md) {
    background-image: url('../../../assets/bg-images/charming-bg-elegant-md.jpg');
  }

  @include media-breakpoint-down(xs) {
    background-image: url('../../../assets/bg-images/charming-bg-elegant-xs.jpg');
  }
}

.block-image-nature {
  background-image: url('../../../assets/bg-images/charming-bg-nature-xl.jpg');
  background-position: center;

  @media screen and (min-width: 1921px) {
    background-image: url('../../../assets/bg-images/charming-bg-nature-xxl.jpg');
  }

  @include media-breakpoint-down(md) {
    background-image: url('../../../assets/bg-images/charming-bg-nature-md.jpg');
  }

  @include media-breakpoint-down(xs) {
    background-image: url('../../../assets/bg-images/charming-bg-nature-xs.jpg');
  }
}

.block-image-watercolor {
  background-image: url('../../../assets/bg-images/charming-bg-watercolor-xl.jpg');
  background-position: center;

  @media screen and (min-width: 1921px) {
    background-image: url('../../../assets/bg-images/charming-bg-watercolor-xxl.jpg');
  }

  @include media-breakpoint-down(md) {
    background-image: url('../../../assets/bg-images/charming-bg-watercolor-md.jpg');
  }

  @include media-breakpoint-down(xs) {
    background-image: url('../../../assets/bg-images/charming-bg-watercolor-xs.jpg');
  }
}

.block-image-workdesk {
  background-image: url('../../../assets/bg-images/charming-bg-workdesk-xl.jpg');
  background-position: center;

  @media screen and (min-width: 1921px) {
    background-image: url('../../../assets/bg-images/charming-bg-workdesk-xxl.jpg');
  }

  @include media-breakpoint-down(md) {
    background-image: url('../../../assets/bg-images/charming-bg-workdesk-md.jpg');
  }

  @include media-breakpoint-down(xs) {
    background-image: url('../../../assets/bg-images/charming-bg-workdesk-xs.jpg');
  }
}

.block-image-lego {
  background-image: url('../../../assets/bg-images/charming-bg-lego-xl.jpg');
  background-position: center;

  @media screen and (min-width: 1921px) {
    background-image: url('../../../assets/bg-images/charming-bg-lego-xxl.jpg');
  }

  @include media-breakpoint-down(md) {
    background-image: url('../../../assets/bg-images/charming-bg-lego-md.jpg');
  }

  @include media-breakpoint-down(xs) {
    background-image: url('../../../assets/bg-images/charming-bg-lego-xs.jpg');
  }
}

.block-image-cupcakes {
  background-image: url('../../../assets/bg-images/charming-bg-cupcakes-xl.jpg');
  background-position: center;

  @media screen and (min-width: 1921px) {
    background-image: url('../../../assets/bg-images/charming-bg-cupcakes-xxl.jpg');
  }

  @include media-breakpoint-down(md) {
    background-image: url('../../../assets/bg-images/charming-bg-cupcakes-md.jpg');
  }

  @include media-breakpoint-down(xs) {
    background-image: url('../../../assets/bg-images/charming-bg-cupcakes-xs.jpg');
  }
}

.block-image-spices {
  background-image: url('../../../assets/bg-images/charming-bg-spices-xl.jpg');
  background-position: center;

  @media screen and (min-width: 1921px) {
    background-image: url('../../../assets/bg-images/charming-bg-spices-xxl.jpg');
  }

  @include media-breakpoint-down(md) {
    background-image: url('../../../assets/bg-images/charming-bg-spices-md.jpg');
  }

  @include media-breakpoint-down(xs) {
    background-image: url('../../../assets/bg-images/charming-bg-spices-xs.jpg');
  }
}

.block-image-jets {
  background-image: url('../../../assets/bg-images/charming-bg-jets-xl.jpg');
  background-position: center;

  @media screen and (min-width: 1921px) {
    background-image: url('../../../assets/bg-images/charming-bg-jets-xxl.jpg');
  }

  @include media-breakpoint-down(md) {
    background-image: url('../../../assets/bg-images/charming-bg-jets-md.jpg');
  }

  @include media-breakpoint-down(xs) {
    background-image: url('../../../assets/bg-images/charming-bg-jets-xs.jpg');
  }
}

.block-image-gokart {
  background-image: url('../../../assets/bg-images/charming-bg-gokart-xl.jpg');
  background-position: center;

  @media screen and (min-width: 1921px) {
    background-image: url('../../../assets/bg-images/charming-bg-gokart-xxl.jpg');
  }

  @include media-breakpoint-down(md) {
    background-image: url('../../../assets/bg-images/charming-bg-gokart-md.jpg');
  }

  @include media-breakpoint-down(xs) {
    background-image: url('../../../assets/bg-images/charming-bg-gokart-xs.jpg');
  }
}

.block-image-flowers {
  background-image: url('../../../assets/bg-images/charming-bg-flowers-xl.jpg');
  background-position: center;

  @media screen and (min-width: 1921px) {
    background-image: url('../../../assets/bg-images/charming-bg-flowers-xxl.jpg');
  }

  @include media-breakpoint-down(md) {
    background-image: url('../../../assets/bg-images/charming-bg-flowers-md.jpg');
  }

  @include media-breakpoint-down(xs) {
    background-image: url('../../../assets/bg-images/charming-bg-flowers-xs.jpg');
  }
}

// A semi-transparent banner at the bottom of the block
.block-callout {
  background-color: $block-callout-bg;
  padding: 1.5rem;
}

// Block on a (vertical) diet
.block-sm {
  padding-bottom: $block-padding / 2;
  padding-top: $block-padding / 2;

  @include media-breakpoint-up(sm) {
    // For clarity: Default block is $block-padding * 2 on this size
    padding-bottom: $block-padding;
    padding-top: $block-padding;
  }
}

.block-lg {
  padding-bottom: $block-padding;
  padding-top: $block-padding;

  @include media-breakpoint-up(sm) {
    // For clarity: Default block is $block-padding * 2 on this size
    padding-bottom: $block-padding * 3;
    padding-top: $block-padding * 3;
  }
}
