// Icons

.icon {
  //standard icon size
  font-size: 28px;
  margin: 0 5px;
}

.icon-sm {
  font-size: 20px;
}

.icon-xs {
  font-size: 16px;
}

.icon-lg {
  font-size: 42px;
}

.icon-xl {
  font-size: 72px;
}
