@mixin merits-variant($merits-gstart, $merits-gstop, $merits-text, $merits-separator, $merits-highlight) {
  @include bg-gradient($merits-gstart, $merits-gstop);
  color: $merits-text;

  .merits-merit {
    border-color: $merits-separator;
  }

  .merits-icon {
    color: $merits-highlight;
  }

  .merits-item {
    border-color: $merits-separator;
  }
}

// Merits
.merits {
  padding: 48px 0;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    padding-bottom: $spacer * .75;
    text-transform: uppercase;
  }
}

.merits-item {
  border-left: 1px solid transparent;
  min-height: 200px;
  padding: 30px 40px;
  padding-left: 40px;

  @include media-breakpoint-down(lg) {
    border-left: 0;
  }

  &:first-child {
    border-left: 0;
  }

  &:last-child {
    border-right: 0;
  }
}

.merits-icon {
  display: inline-block;
  font-size: 75px;
  margin-bottom: 20px;
  margin-left: 0;
  margin-right: 0;

  &.fa {
    font-size: 50px;
  }
}

@each $name, $colors in $theme-colors-extended {
  .merits-#{$name} {
    @include merits-variant (
                    map-get($colors, gstart),
                    map-get($colors, gstop),
                    map-get($colors, contrast),
                    map-get($colors, border),
                    map-get($colors, highlight)
    );
  }
}
