@each $color, $value in $theme-colors {
  .btn-hover-text-#{$color} {
    &:hover {
      color: $value;
    }
  }
}

.btn-wide {
  padding-left: 50px;
  padding-right: 50px;
}

.display-fix {
  margin-left: -4px;
}

.btn-pill {
  border-radius: 100px;
}

.lead-lg {
  font-size: 35px;
}
