// Spacers
$spacer: 1rem !default; //16px //BS

$spacers: (
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 3),
  6: ($spacer * 3.75),
  7: ($spacer * 4.5),
  8: ($spacer * 6),

) !default; //BS

//Typography
$font-family-sans-serif:   'Lato', Arial, sans-serif !default; // BS
$line-height-base: 1.5 !default; // 24px // BS
$line-height-lg: 1.44 !default; // 36px (from 25) // BS

$h1-font-size: 2.4375rem !default; // 39px // BS
$h2-font-size: 1.9375rem !default; // 31px // BS
$h3-font-size: 1.5625rem !default; // 25px // BS
$h4-font-size: 1.25rem !default; //20px // BS
$h5-font-size: 1.25rem !default; //20px // BS
$h6-font-size: 1rem !default; // 16px // BS

$display1-size: 5.9375rem !default; // 95px // BS
$display2-size: 4.75rem !default; // 76px // BS
$display3-size: 3.8125rem !default; // 61px // BS
$display4-size: 3.0625rem !default; // 48px // BS

// Jumbotron
$jumbotron-padding:              2rem !default; //BS

// Components
$border-radius:          5px !default; // 5px // BS
$border-radius-lg:       $border-radius !default; // BS
$border-radius-sm:       $border-radius !default; // BS

// Buttons
$btn-padding-y:                  10px !default; // BS
$btn-line-height:                1 !default; // BS

$btn-padding-y-sm: .5rem !default; //BS
$btn-padding-x-sm: .75rem !default; //BS
