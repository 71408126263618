// ---------------------------------
// ---------- CHARMING -------------
// ---------------------------------

$accent-1: #35bd49 !default;
$accent-2: #353844 !default;

$primary: #2284fe !default;
$secondary: #78baf8 !default;
$success: $accent-1 !default;
$info: #5a7ec5 !default;
$warning: #f0a249 !default;
$danger: #fc2344 !default;
$light: #e5e5e5 !default;
$dark: #3b3b3b !default;

$body-color-soft: #828282 !default;

// Buttons
$colored-button-text-shadow: rgba(0, 0, 0, .07) !default;

//Forms
$charming-form-input-bg: rgba(255, 255, 255, .8) !default;

// Navigation
$navbar-dark-mobile-nav-bg: rgba(255, 255, 255, .2) !default;
$navbar-dark-mobile-nav-highlight: rgba(255, 255, 255, .4) !default;

$navbar-light-mobile-nav-bg: rgba(0, 0, 0, .06) !default;
$navbar-light-mobile-nav-highlight: rgba(0, 0, 0, .10) !default;

// Features
$img-simple-border: #2d3842 !default;


// ---------------------------------
// ---------- BOOTSTRAP ------------
// ---------------------------------

$body-color: #373a3c !default; //BS

$green: #35bd49 !default;
$cyan: #4ac1ee !default;
$orange: #fba91e !default;
$red: #fa2840 !default;

// Adding accents to bootstrap
$theme-colors: () !default;
$theme-colors: map-merge((
  "accent-1": $accent-1,
  "accent-2": $accent-2
), $theme-colors);

// Navbar
$navbar-dark-color: rgba(255, 255, 255, .9) !default; //BS
$navbar-dark-hover-color: rgba(255, 255, 255, .95) !default; //BS
$navbar-dark-toggler-border-color: rgba(255, 255, 255, .1) !default; //BS

// Navigation
$navbar-light-color:                #343434 !default; //BS
$navbar-light-active-color:         #4c4c4c !default; //BS

$navbar-dark-color:                 #fff !default; //BS
$navbar-dark-hover-color:           darken($navbar-dark-color, 13%) !default; //BS
$navbar-dark-active-color:          #fff !default; //BS
$navbar-dark-disabled-color:        #fff !default; //BS

// Forms
$custom-control-indicator-checked-bg:         #494949 !default; //BS
$custom-checkbox-indicator-indeterminate-bg: #494949 !default; //BS

// scss-lint:disable ColorVariable
$custom-control-indicator-focus-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .2rem #494949 !default; //BS
// scss-lint:enable ColorVariable

$input-focus-border-color:   #898989 !default; //BS

// Links
$link-color: $accent-1 !default; //BS
